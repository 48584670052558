<!-- Progress dashboard starts -->
<div class="page-container">
  <div class="page-heading">
    <h3>
      <img class="nav-icon" src="assets/icons/list_alt_black_24dp.svg">
      <span class="page-title">{{'sideNavigation.titleDashboard' | translate}}</span>
    </h3>
      <div class="col-auto">
          <img class="card-icon" src="assets/icons/info_blue_24dp.svg" (mouseover)="showColors = true" (mouseout)="showColors = false">
          <div *ngIf="showColors" class="colorbox">
            <div class="blue-text">All</div>
              <div>
                  <span class="dot" [ngStyle]="{'background-color': interruptedColor}"></span>
                  {{'basic.interrupted' | translate}}
              </div>
              <div>
                  <span class="dot" style="background-color: white; border: 1px solid red"></span>
                  {{'basic.deviation' | translate}}
              </div>
              <div class="blue-text">
                <span *ngIf="customerId === 2">String</span>
                <span *ngIf="customerId !== 2">Frame</span>
              </div>
              <div *ngFor="let state of allStates.states | filterStatesByType: 1">
                
                  <span class="dot" [ngStyle]="{'background-color': state.color}"></span>
                  {{ state.definition }}
              </div>
              <div class="blue-text">Inverters</div>
              <div *ngFor="let state of allStates.states | filterStatesByType: 2">
                
                  <span class="dot" [ngStyle]="{'background-color': state.color}"></span>
                  {{ state.definition }}
              </div>
          </div>

      </div>
  </div>
  <div class="page-content">

    <div class="map-height">
      <div id="mapProject" class="map-container structure-child"></div>
    </div>

    <div class="justify-content-center w-100 d-flex" *ngIf="pageLoading">
      <load-spinner *ngIf="pageLoading"></load-spinner>
    </div>
    <section class="section" *ngIf="!pageLoading">
      <h4>{{'planner.projectDashboard.titleStages' | translate}}</h4>
      <div class="card shadow-sm state-class p-3" *ngFor="let state of filterNotStartedState(allStates.states)">
        <hr>
        <h5><span class="dot" [ngStyle]="{'background-color': state.color}"></span> 
          {{state.definition}} - 
          <span *ngIf="state.type === 1" class="blue-text">
            <span *ngIf="customerId === 2">String</span>
            <span *ngIf="customerId !== 2">Frame</span>
          </span>
          <span *ngIf="state.type === 2" class="blue-text">Inverter</span>
          <span *ngIf="state.type === 3" class="blue-text">Checkup</span>
        </h5>
        <div class="fw-bold mb-2">
          <span *ngIf="state.type !== 3">{{'planner.projectDashboard.workorders' | translate }}</span>
          <span *ngIf="state.type === 3">Valmiita tässä tilanteessa olevia työmääräimiä</span>
          : {{state.workorders.amount}}</div>
        <div class="row">
            <div class="col-4" *ngIf="state.type !== 3">
                <h4>{{'planner.projectDashboard.today' | translate}}</h4>
                <div>{{'basic.done' | translate }}: 
                  <span class="green-text">{{state.workorders.todaydone}}/{{ state.workorders.todaytotal}}</span>
                </div>
                <div>{{'planner.projectDashboard.interrupted' | translate }}: 
                  <span [ngClass]="state.workorders.todayinterrupted > 0 ? 'red-text' : '' ">{{state.workorders.todayinterrupted}}</span>
                </div>
            </div>
            <div class="col-4" *ngIf="state.type !== 3">
                <h4>{{'planner.projectDashboard.thisWeek' | translate}}</h4>
                <div>{{'basic.done' | translate }}: 
                  <span class="green-text">{{state.workorders.weekdone}}/{{state.workorders.weektotal}}</span>
                </div>
                <div>{{'planner.projectDashboard.interrupted' | translate }}: 
                  <span [ngClass]="state.workorders.weekinterrupted > 0 ? 'red-text' : '' ">{{state.workorders.weekinterrupted}}</span>
                </div>
            </div>
            <div class="col-4">
                <h4>{{'planner.projectDashboard.wholeProject' | translate}}</h4>
                <div>{{'basic.done' | translate }}: <span class="green-text">
                  <span *ngIf="state.type !== 3">{{state.workorders.totaldone}}</span>
                  <span *ngIf="state.type === 3">{{getDoneAmount(state)}}</span>
                  / {{getAmount(state)}}</span>
                </div>
                <div *ngIf="state.type !== 3">
                    {{'planner.projectDashboard.interrupted' | translate }}: <span [ngClass]="state.workorders.totalinterrupted > 0 ? 'red-text' : '' ">{{state.workorders.totalinterrupted}}</span>
                </div>
            </div>
        </div>

      </div>
    </section>
      <div class="rightsidebar" *ngIf="showSidebar">
          <div class="sidebarcontent">
              <img src="assets/icons/close_red_24dp.svg" class="close-btn" (click)="closeSidebar()" />
              <h2 *ngIf="selectedMarker.identifier">
                <span *ngIf="customerId === 2 && selectedMarker.transformer">{{ selectedMarker.transformer }} - {{selectedMarker.inverter}}<span *ngIf="selectedMarker.circuit"> - {{selectedMarker.circuit}}</span></span>
                <span *ngIf="customerId === 2 && !selectedMarker.transformer">{{selectedMarker.identifier}}</span>
                <span *ngIf="customerId !== 2">{{selectedMarker.id}}</span>
              </h2>
              <h2 *ngIf="!selectedMarker.identifier && selectedMarker.inverter">{{ selectedMarker.transformer }} - {{selectedMarker.inverter}}</h2>

              <div *ngIf="selectedMarker.status_id">{{ 'planner.projectDashboard.currentPhase' | translate }}: {{getStatusDefinition()}}</div>

              <div *ngIf="selectedMarker.images">
                  <img *ngFor="let img of selectedMarker.images" src="{{img.url}}" alt="{{img.title}}" class="thumbnail" (click)="selectedImage = img.url" />
              </div>
              <hr>
              <div>
                  <h4>{{ 'planner.projectDashboard.reports' | translate }}</h4>
                  <div *ngFor="let report of selectedMarker.reports">
                      <span class="dot" style="margin-left: 6px;" [ngStyle]="{'background-color': allStates.states[report.status_id]?.color}"></span>
                      {{report.created | date: 'dd.MM.yyyy HH:MM' }}
                      <br>
                      <img class="person-icon" src="assets/icons/person_blue_24dp.svg"> {{report.firstname}} {{report.lastname}}
                  </div>
              </div>
              <h4>{{ 'planner.projectDashboard.images' | translate }} (
                <span *ngIf="selectedMarker.images">{{selectedMarker.imagecount}}</span>
                <span *ngIf="!selectedMarker.images">0</span>
              )</h4>
              <div *ngFor="let state of allStates.states | filterStatesByType: type">
                  <button type="button" class="accordion" (click)="state.show = !state.show">
                      <span class="dot" [ngStyle]="{'background-color': state.color}"></span>
                      {{ state.definition }} ({{ selectedMarker?.statuses?.[state.id]?.count || 0 }})
                      <img [hidden]="!state.show" src="assets/icons/expand_less_black_24dp.svg"/>
                      <img [hidden]="state.show"  src="assets/icons/expand_more_black_24dp.svg"/>
                  </button>
                  <div *ngIf="selectedMarker?.statuses?.[state.id]?.images && state.show" class="panel">
                      <img *ngFor="let img of selectedMarker.statuses[state.id].images" src="{{img.url}}" alt="{{img.title}}" class="thumbnail" (click)="selectedImage = img.url" />
                  </div>
              </div>
              <hr>
          </div>
      </div>
      <div *ngIf="isLoading" class="justify-content-center d-flex spinner">
          <load-spinner></load-spinner>
      </div>
      <!-- Full-size Image Modal -->
      <div class="modal" *ngIf="selectedImage" (click)="selectedImage = null">
          <div class="modal-content" (click)="$event.stopPropagation()">
              <span class="close" (click)="selectedImage = null">&times;</span>
              <img [src]="selectedImage" class="image-size-full">
          </div>
      </div>
  </div>

</div>
<!-- Project dashboard ends -->
